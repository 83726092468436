<template>
  <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
    <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-5">
      <select
        class="flix-form-control"
        v-model="date[2]"
        @change.prevent="setDate()"
      >
        <option
          v-for="(day, index) in days"
          :key="index"
          :value="getNullsBefore(index)"
          >{{ day }}</option
        >
      </select>
      <select
        class="flix-form-control"
        v-model="date[1]"
        @change.prevent="
          date[2] = '01';
          setDate();
        "
      >
        <option
          v-for="(month, index) in months"
          :key="index"
          :value="getNullsBefore(index + 1)"
          >{{ month }}</option
        >
      </select>
      <select
        class="flix-form-control"
        v-model="date[0]"
        @change.prevent="
          date[2] = '01';
          setDate();
        "
      >
        <option v-for="year in years" :key="year">{{ year }}</option>
      </select>
    </div>
    <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-5">
      <icon id="clock" class="flix-desktop" />
      <timePicker :range="range" :time="hours" :callback="setHours" />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    timePicker() {
      return import("@/components/default/form/timePicker");
    }
  },
  props: {
    time: String,
    callback: Function,
    range: {
      type: [Number, String],
      default() {
        return 15;
      }
    }
  },
  data() {
    return {
      days: {},
      years: this.getYear(),
      months: this.$t("dates.months"),
      date: this.time.split(" ")[0].split("-"),
      hours: this.time.split(" ")[1]
    };
  },
  computed: {},
  mounted() {
    this.getDays();
  },
  methods: {
    getDays() {
      var r = {};
      var daysOfMonth = new Date();
      daysOfMonth.setYear(this.date[0] * 1);
      daysOfMonth.setDate(1);
      daysOfMonth.setMonth(this.date[1] * 1);
      daysOfMonth.setDate(0);
      var end = daysOfMonth.getDate();
      for (var d = 1; d <= end; d++) {
        daysOfMonth.setDate(d);
        var weekday = daysOfMonth.getDay();
        if (!weekday) {
          weekday = 7;
        }
        weekday = weekday - 1;
        weekday = this.$t("dates.weekdays")[weekday];
        r[d] = weekday[0] + weekday[1] + ". " + this.getNullsBefore(d) + ".";
      }
      this.days = r;
    },
    setDate() {
      var date =
        this.date[0] +
        "-" +
        this.getNullsBefore(this.date[1]) +
        "-" +
        this.getNullsBefore(this.date[2]) +
        " " +
        this.hours;

      this.getDays();
      this.callback(date);
    },
    setHours(h) {
      var date = this.time.split(" ")[0] + " " + h;

      this.callback(date);
    },
    getYear() {
      var a = new Date().getFullYear();
      var b = a + 1;

      return [a, b];
    },
    getNullsBefore(i) {
      i = i * 1;
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
  }
};
</script>
<style lang="sass" scoped>
.datepicker
  display: flex
  flex-direction: row
  .picker
    display: inline-flex
    align-items: center
    justify-content: center
    .icon
      font-size: 16pt
    select
      margin: 0 2px
      border: 1px solid grey
      outline: none
      background-color: white
@media (max-width: 500px)
  .datepicker
    flex-direction: column
</style>
